import React, { ReactElement, useState } from 'react'
import './AboutUs.scss'
import { graphql, Link } from 'gatsby'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import loadable from '@loadable/component'
import Seo from '../../components/Seo/Seo'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const Modal = loadable(() => import('../../components/Modal/Modal'))

type AboutUsAndFactsPropTypes = {
  data: {
    aboutUs: {
      title: string
      slug: string
      body: {
        value: string
      }[]
      images: {
        description: string
        featured: string
        url: string
      }[]
    }
    fact?: {
      id: string
      images: {
        description: string
        featured: string
        url: string
      }[]
      content: {
        body: string
        title: string
      }[]
    }
  }
}

const AboutUs = ({
  data: { aboutUs, fact },
}: AboutUsAndFactsPropTypes): ReactElement => {
  const [isLocationDetailsShowing, setIsLocationDetailsShowing] = useState<
    boolean
  >(false)

  return (
    <Layout>
      <Seo
        title="About Amaia Land"
        ogMetaData={{
          description:
            'Amaia Land Corp. is a wholly-owned subsidiary of Ayala Land Inc. (ALI) that caters to a broad affordable market segment.',
        }}
      />

      <div className="about-us">
        <div className="about-us-profile">
          <div className="about-us-profile-info">
            <h1 className="title">{aboutUs?.title}</h1>
            <div
              className="content"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: aboutUs?.body[0]?.value }}
            />
          </div>
          <div className="about-us-profile-image-container">
            {aboutUs?.images[0]?.url && (
              <LazyLoadImage
                src={aboutUs?.images[0]?.url}
                alt="assetContainer"
                placeholder={<img id="placeholder" alt={aboutUs?.title} />}
              />
            )}
          </div>
        </div>
        <div className="five-pillars">
          <button
            type="button"
            onClick={(): void => setIsLocationDetailsShowing(true)}
          >
            View our Five (5) Pillars
          </button>
        </div>
        <Modal
          className="left"
          side
          noIcons
          isShowing={isLocationDetailsShowing}
          onClose={(): void => setIsLocationDetailsShowing(false)}
        >
          <div className="details-about-us">
            <h4 className="title">Five (5) Pillars</h4>
            {fact?.images[0]?.url && (
              <LazyLoadImage
                src={fact?.images[0]?.url}
                alt="property-banner"
                placeholder={
                  <img id="placeholder" alt="Five (5) Pillars banner" />
                }
                className="selected-property-location-details-banner"
              />
            )}

            <div className="details-about-us-content">
              {fact?.content.map((pillar) => {
                return (
                  <ul key={pillar?.title}>
                    <li>
                      <h1 className="pillar-title">{pillar?.title}</h1>
                      <p
                        className="description"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: pillar?.body }}
                      />
                    </li>
                  </ul>
                )
              })}
            </div>
          </div>
        </Modal>

        <div className="other-details">
          <div className="other-details-categories">
            <Link to="/vision-mission" className="link">
              <button type="button" className="">
                Mission and Vision
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs

export const pageQuery = graphql`
  query AboutUsAndFactsQuery {
    aboutUs {
      ...AboutUsFields
    }
    fact {
      ...FivePillarsFields
    }
  }
`
